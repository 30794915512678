import API from '../../services/api'

export default {
  name: 'forgot_password',
  components: {},
  props: [],
  data: function () {
    return {
      style: {
        bg: '#1f214c',
        secondary_bg: '#5258c2'
      },
      errors: [],
      msgKey: null,
      redirectMsg: null,
      input: {
        email: ''
      },
      disabled: false
    }
  },
  methods: {
    forgotPassword: function (e) {
      const validateForm = (input) => {
        this.errors = []
        this.msgKey = null
        this.redirectMsg = null
        const mail = input.email
        const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (mailformat.test(mail) === false) {
          this.errors.push({ email: ['forgot_password.invalid_email'] })
        }
        if (!mail) {
          this.errors.push({ email: ['forgot_password.required_email'] })
        }
        return this.errors.length === 0
      }

      if (!validateForm(this.input)) {
        e.preventDefault()
        return
      }
      this.disabled = true

      API.post('customer/lost_password', { email: this.input.email }).then(res => {
        if (res.data.success === false) {
          this.msgKey = 'forgot_password.failed'
          this.disabled = false
          return
        }
        this.msgKey = 'forgot_password.success'
        this.redirectMsg = 'forgot_password.redirect_msg'

        setTimeout(() => {
          this.$router.push({
            name: 'login_email',
            query: {
              reference: 'forgot_password'
            }
          })
        }, 5000)
      }).catch(err => {
        this.disabled = false
        console.log(err)
      })
      e.preventDefault()
    }
  }
}
