/* eslint-disable */

import axios from 'axios'
import API from '../../services/api'

export default {
  name: 'history',
  components: {},
  props: [],
  data () {
    return {
      results: [],
      history_a: [],
      next_page: null,
      spinner: true
    }
  },
  mounted: function () {
    let c = this.$session.get('campaign')
    this.token = this.$session.get('tokenTerminal')
    if (c) {
      this.campaign = c
    }
    
    this.get_history('transaction/history')
    
    let terminalToken = this.$session.get('terminal_token')
    if (terminalToken) {
      this.token = terminalToken
      this.$session.set('terminal_token', null)
    }
  },
  methods: {
    padTo2Digits: function (num) {
      return num.toString().padStart(2, '0')
    },
    formatDate: function (date) {
      return (
        [
          this.padTo2Digits(date.getDate()),
          date.toLocaleString('default', { month: 'short' }),
          date.getFullYear()
        ].join(' de ') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
          this.padTo2Digits(date.getSeconds())
        ].join(':')
      )
    },
    get_history: function async (url) {
      if (this.results.length > 0 &&  this.next_page) {
        url = this.next_page
      }
      API.get(url).then(
        response => {
          this.history_a = response.data
          this.next_page = this.history_a.meta.next_page
          let results_temp = this.history_a.results
          if (results_temp) {
            let results = this.modify_api_response(results_temp)
            if (this.results.length > 0 &&  this.next_page) {
              this.results.push(...results)
              } else {
                this.results = results
              }
           }
           this.spinner = false
        } ,
        error => {
          this.handle_error(error)
        }
      )
    },
    modify_api_response: function (arr) {
      arr.forEach(element => {
        element.take_date = this.formatDate(new Date(element.take_time * 1000))
        if (element.plan_name == '') {element.no_plan = true}
        if(element.return_time === 0){
          element.elapsed_time = 'DEVOLUÇÃO PENDENTE'
        } else {
          element.return_date = this.formatDate(new Date(element.return_time * 1000))
          var time = element.elapsed_time
          var hours = Math.floor(time / 3600)
          var min = Math.floor((time - (hours * 3600)) / 60)
          var sec = time - (hours * 3600) - (min * 60)
          
          var hours_pad = this.padTo2Digits(hours)
          var min_pad = this.padTo2Digits(min)
          var sec_pad = this.padTo2Digits(sec)
          
          var hours_str = hours_pad != '00' ? hours_pad + ' h ' + 'e ' : ''
          var min_str = min_pad != '00' ? min_pad + ' min' + ' e ' : ''
          var sec_str = sec_pad
          element.elapsed_time = hours_str + min_str + sec_str + ' sec'
        }
      })
      return arr
    },
    go_back() {
      this.$router.go(-1)
    },
    handle_error: function (error) {
      if (error.status && error.status === 401) {
        this.$parent.log_out()
      } else {
        this.modal_error = error
        this.open_modal(this.$refs.modal_error)
        console.log(error)
      }
    },
    update_last_transaction: function () {
      API.get('transaction/history?page=1&items_per_page=1')
        .then(
          response => {
            if (response.data) {
              this.last_transaction = response.data.last_transaction

              let now = new Date().getTime()
              let past = this.last_transaction.take_time * 1000
              let freeTime = this.last_transaction.free_time * 1000

              if (now - past > freeTime) {
                this.last_transaction['on_free_time'] = false
              } else {
                this.last_transaction['on_free_time'] = true
              }
            }
          },
          error => {
            this.handle_error(error)
          }
        )
    }
  }
}
