/* global fbq */
import axios from 'axios'
import API from '../../services/api'

import '../../main.scss'

const uuid = require('device-uuid')

export default {
  name: 'home',
  components: {},
  props: [],
  data: function () {
    return {
      campaign: null,
      card: {
        number: '',
        expiration_date: '',
        cvs: '',
        name: '',
        cpf: '',
        passport: ''
      },
      token: '',
      cable_type: null,
      user: null,
      plans: null,
      user_devices: null,
      last_transaction: null,
      selected_card: null,
      use_cpf: true,
      position: null,
      terminal: {
        campaign: false,
        far: false
      },
      user_permission: {
        info_sharing: null,
        info_sharing_anyway: false,
        credit_reservation: null,
        credit_reservation_always: false
      },
      qr_code_on: false,
      take_type: 'terminal',
      modal_error: '',
      qr_type: null,
      limit: '',
      name: '',
      email: '',
      plan: '',
      latitude: null,
      longitude: null,
      precision: null,
      batteryLevel: null,
      batteryCharging: null,
      batteryTimeRemaining: null,
      isBatterySupported: 'getBattery' in navigator,
      cableSelection: 0
    }
  },
  created () {
    if (this.isBatterySupported) {
      navigator.getBattery().then((battery) => {
        this.updateBatteryStatus(battery)
        battery.addEventListener('levelchange', () => {
          this.updateBatteryStatus(battery)
        })
        battery.addEventListener('chargingchange', () => {
          this.updateBatteryStatus(battery)
        })
      })
    }
  },
  computed: {},
  mounted: function () {
    let c = this.$session.get('campaign')
    this.token = this.$session.get('tokenTerminal')
    if (c) {
      this.campaign = c
    }

    this.send_device_info()

    this.$getLocation({
      enableHighAccuracy: true
    }).then(
      coordinates => {
        this.latitude = coordinates.lat
        this.longitude = coordinates.lng
        this.precision = coordinates.accuracy
        this.get_user('customer/me/' + this.latitude + '/' + this.longitude)
      },
      () => {
        this.get_user('customer/me')
      }
    )

    let terminalToken = this.$session.get('terminal_token')
    if (terminalToken) {
      this.token = terminalToken
      this.$session.set('terminal_token', null)
    }
  },
  methods: {
    updateBatteryStatus (battery) {
      this.batteryLevel = (battery.level * 100).toFixed(0)
      this.batteryCharging = battery.charging ? 'Yes' : 'No'
    },
    reload_page () {
      location.reload()
    },
    padTo2Digits: function (num) {
      return num.toString().padStart(2, '0')
    },
    formatDate: function (date) {
      return (
        [
          this.padTo2Digits(date.getDate()),
          date.toLocaleString('default', { month: 'short' }),
          date.getFullYear()
        ].join(' de ')
      )
    },
    onQrInit: async function (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.handle_error('ERROR: you need to grant camera access permisson')
        } else if (error.name === 'NotFoundError') {
          this.handle_error('ERROR: no camera on this device')
        } else if (error.name === 'NotSupportedError') {
          this.handle_error('ERROR: secure context required (HTTPS, localhost)')
        } else if (error.name === 'NotReadableError') {
          this.handle_error('ERROR: is the camera already in use?')
        } else if (error.name === 'OverconstrainedError') {
          this.handle_error('ERROR: installed cameras are not suitable')
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.handle_error('ERROR: Stream API is not supported in this browser')
        }
      }
    },
    onQrDecode: function (result) {
      this.token = result.split('qr/')[1]
      // console.log('TOKEN:', this.token)
      this.qr_code_on = false
      this.confirm_rent()
    },
    scan_qr: function () {
      this.qr_code_on = true
    },
    send_device_info: function () {
      let thisUUID = new uuid.DeviceUUID().get()
      const deviceInfo = new uuid.DeviceUUID().parse()
      API.post('customer/device_info', {
        device_id: thisUUID,
        manufacturer: null,
        model: null,
        platform: deviceInfo.browser,
        platform_version: deviceInfo.version,
        app_version: '1.2.0',
        carrier_name: null,
        country_code: null,
        phone_number: null,
        sim_serial_number: null
      })
        .then(response => { }, error => {
          this.handle_error(error)
        })
    },
    searchNewDevice: function () {
      let deviceId = new uuid.DeviceUUID().get()
      if (deviceId != null && this.user_devices != null) {
        let newDevice = false
        for (const element of this.user_devices) {
          if (element.uuid === deviceId && element.action2_count !== 0) {
            newDevice = true
            break // exit the loop
          }
        }
        if (!newDevice) {
          this.send_battery_status(deviceId)
        }
      }
    },
    send_battery_status: function (deviceId) {
      console.log('Não pode registar!!!')
      API.post('battery/save_history', {
        action: 'registration',
        charge: this.batteryLevel,
        long: this.longitude,
        lat: this.latitude,
        device_id: deviceId,
        precision: this.precision
      }).then(response => {
        console.log(response.data)
      })
    },
    get_user: function (url) {
      API.get(url).then(
        response => {
          if (response.data) {
            this.user = response.data
            this.name = this.user.name
            this.email = this.user.email
            this.plan = this.user.plan.name
            this.user_devices = this.user.devices
            this.searchNewDevice()
            if (this.user.signature) {
              this.limit = this.formatDate(new Date(this.user.signature.expiration * 1000))
            }
            this.update_last_transaction()
            setInterval(() => {
              this.update_last_transaction()
            }, 60000)
            axios.get(process.env.VUE_APP_MOBILE_API + 'plans').then(
              response => {
                if (response.data) {
                  this.plans = response.data
                }
              },
              error => {
                this.handle_error(error)
              }
            )
          }
        },
        error => {
          this.handle_error(error)
        }
      )
    },
    handle_error: function (error) {
      if (error.status && error.status === 401) {
        this.$parent.log_out()
      } else {
        this.modal_error = error
        this.open_modal(this.$refs.modal_error)
        console.log(error)
      }
    },
    update_last_transaction: function () {
      API.get('transaction/history?page=1&items_per_page=1')
        .then(
          response => {
            if (response.data) {
              this.last_transaction = response.data.last_transaction

              let now = new Date().getTime()
              let past = this.last_transaction.take_time * 1000
              let freeTime = this.last_transaction.free_time * 1000

              if (now - past > freeTime) {
                this.last_transaction['on_free_time'] = false
              } else {
                this.last_transaction['on_free_time'] = true
              }
            }
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    register_credit_card: function () {
      return new Promise(resolve => {
        API.post('credit_card/register', {
          name: this.card.name.toUpperCase(),
          cpf: this.card.cpf.replace(/\D+/g, ''),
          number: this.card.number.replace(/\D+/g, ''),
          cvs: this.card.cvs,
          expiration_date: this.card.expiration_date,
          passport: this.card.passport
        })
          .then(
            response => {
              if (response.data.success) {
                fbq('track', 'AddPaymentInfo')
                this.$router.push({ name: 'home' })
                this.$forceUpdate()
                resolve()
              } else {
                setTimeout(() => {
                  this.close_all_modal()
                  if (typeof (response.data.errors) === 'string') {
                    this.handle_error(response.data.errors)
                  } else {
                    let err = ''
                    for (let key in response.data.errors) {
                      err += response.data.errors[key] + '<br><br>'
                    }
                    this.handle_error(err)
                  }
                  this.$forceUpdate()
                  resolve()
                }, 1500)
              }
            },
            error => {
              this.handle_error(error)
            }
          )
      })
    },
    take_battery: function () {
      this.close_all_modal()
      this.open_modal(this.$refs.take_battery_processing_modal)

      let customLink = null
      if (this.campaign) {
        customLink = this.campaign.custom_link
      }

      let endpoint = 'transaction/take_battery'
      if (this.take_type !== 'terminal') {
        endpoint = 'charger/transaction'
      }

      API.post(endpoint, {
        token: this.token,
        cable_type: this.cable_type,
        cancel: false,
        terminal_far_away: this.terminal.far,
        custom_link: customLink
      })
        .then(
          response => {
            setTimeout(() => {
              this.close_all_modal()
              if (response.data.success) {
                this.open_modal(this.$refs.take_battery_modal)
                this.get_transaction_status(response.data.transaction_id)
              } else {
                if (response.data.error) {
                  this.handle_error(response.data.error)
                } else {
                  this.handle_error('Falha no pagamento')
                }
              }
            }, 1000)
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    get_transaction_status: function (id) {
      API.get('transaction/status/' + id)
        .then(
          response => {
            setTimeout(() => {
              if (response.data.timeouted) {
                this.close_all_modal()
                this.handle_error('Terminal offline')
              } else if (response.data.success) {
                if (response.data.status === 'take_battery_success') {
                  location.reload()
                  console.log('Bateria retirada com sucesso')
                  fbq('track', 'Purchase')
                } else if (response.data.status === 'take_battery_error') {
                  this.close_all_modal()
                  this.handle_error('A bateria não foi retirada')
                } else if (response.data.status === 'firmware_communication_error') {
                  this.close_all_modal()
                  this.handle_error('Ocorreu um erro ao tentar se comunicar com o terminal')
                } else {
                  setTimeout(() => {
                    this.get_transaction_status(id)
                  }, 3000)
                }
              } else {
                if (response.data.message) {
                  this.close_all_modal()
                  this.handle_error(response.data.message)
                }
              }
            }, 1000)
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    open_modal: function (modal) {
      try {
        setTimeout(() => {
          modal.show()
        }, 1000)
      } catch (error) {
        this.open_modal(modal)
      }
    },
    close_all_modal: function (modal) {
      this.$refs.pernissions_modal.hide()
      this.$refs.campaign_modal.hide()
      this.$refs.credit_reservation_modal.hide()
      this.$refs.terminal_far_modal.hide()
      this.$refs.register_credit_card_modal.hide()
      this.$refs.verify_terminal_modal.hide()
      this.$refs.take_battery_modal.hide()
      this.$refs.take_battery_processing_modal.hide()
    },
    isEmptyObject: function (obj) {
      for (var key in obj) {
        return false
      }
      return true
    },
    reset_rent_variables: function () {
      this.modal_error = ''
      this.terminal.campaign = false
      this.terminal.far = false
      this.position = null
      this.user_permission.info_sharing = null
      this.user_permission.info_sharing_anyway = false
      this.user_permission.credit_reservation = null
      this.user_permission.credit_reservation_always = false
    },
    confirm_rent: function () {
      this.reset_rent_variables()

      if (isNaN(parseInt(this.token))) {
        console.log('charger')
        this.take_type = 'charger'
      }

      if (!this.campaign || (this.campaign && !this.campaign.no_payment)) {
        if (this.user.credit_cards.length <= 0 && this.user.is_user_on_demand) {
          this.close_all_modal()
          this.open_modal(this.$refs.register_credit_card_modal)
          this.register_credit_card().then(() => {
            if (this.modal_error === '') {
              this.user.credit_cards.push(this.card)
              this.verify_terminal()
            }
          })
        } else {
          this.verify_terminal()
        }
      } else {
        this.verify_terminal()
      }
    },
    verify_terminal: function () {
      if (this.take_type !== 'terminal') {
        this.credit_reservation()
      } else {
        this.close_all_modal()
        this.open_modal(this.$refs.verify_terminal_modal)
        this.$getLocation({
          enableHighAccuracy: true
        }).then(
          coordinates => {
            this.position = { lat: coordinates.lat, lng: coordinates.lng }
            this.complete_verify_terminal()
          },
          () => {
            this.complete_verify_terminal()
          }
        )
      }
    },
    complete_verify_terminal: function () {
      API.post('transaction/check_terminal', {
        token: this.token,
        position: this.position
      })
        .then(
          response => {
            setTimeout(() => {
              this.terminal.campaign = response.data.campaign

              this.terminal.far = 0
              if (this.position) {
                if (response.data.far_away) {
                  this.terminal.far = 2
                } else {
                  this.terminal.far = 1
                }
              }

              if (response.data.far_away) {
                this.close_all_modal()
                this.open_modal(this.$refs.terminal_far_modal)
              } else {
                this.credit_reservation()
              }
            }, 1000)
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    credit_reservation: function () {
      if (!this.campaign || (this.campaign && !this.campaign.no_payment)) {
        if (
          this.user.user_permission.transaction_permission_granted === 3 ||
          !this.user.is_user_on_demand
        ) {
          this.campaign_permission()
        } else {
          this.close_all_modal()
          this.open_modal(this.$refs.credit_reservation_modal)
        }
      } else {
        this.campaign_permission()
      }
    },
    complete_credit_reservation: function (ok) {
      if (ok) {
        fbq('trackCustom', 'creditCardReservation')
        if (this.user_permission.credit_reservation_always) {
          this.user_permission.credit_reservation = 3
        } else {
          this.user_permission.credit_reservation = 2
        }
        this.campaign_permission()
      } else {
        this.user_permission.credit_reservation = 1
        this.send_user_permissions()
      }
    },
    campaign_permission: function () {
      if (this.terminal.campaign) {
        this.close_all_modal()
        this.open_modal(this.$refs.campaign_modal)
      } else {
        this.send_user_permissions()
      }
    },
    complete_campaign_permission: function (ok) {
      fbq('trackCustom', 'campaignPermission')
      if (ok) {
        if (this.user_permission.info_sharing_anyway) {
          this.user_permission.info_sharing = 3
        } else {
          this.user_permission.info_sharing = 2
        }
      } else {
        this.user_permission.info_sharing = 1
      }
      this.send_user_permissions()
    },
    send_user_permissions: function () {
      this.close_all_modal()
      this.open_modal(this.$refs.pernissions_modal)

      axios
        .post(process.env.VUE_APP_MOBILE_API + 'transaction/permission', {
          transaction_permission_granted: this.user_permission.credit_reservation,
          info_sharing_permission_granted: this.user_permission.info_sharing
        })
        .then(
          response => {
            setTimeout(() => {
              if (this.user_permission.credit_reservation === 1) {
                this.cancel_transaction(null)
              } else if (this.user_permission.info_sharing === 1) {
                this.cancel_transaction(null)
              } else {
                this.take_battery()
              }
            }, 1000)
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    cancel_transaction: function (reason) {
      var endpoint = 'transaction/take_battery'
      if (this.take_type !== 'terminal') {
        endpoint = 'charger/transaction'
      }

      API.post(endpoint, {
        token: this.token,
        cable_type: this.cable_type,
        cancel: true,
        cancel_reason: reason,
        terminal_far_away: this.terminal.far,
        custom_link: null
      })
        .then(
          () => {
            setTimeout(() => {
              this.close_all_modal()
            }, 1000)
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    open_delete_card_modal: function (card) {
      try {
        this.selected_card = card
        this.$refs.delete_card_modal.show()
      } catch (error) {
        setTimeout(() => {
          this.open_delete_card_modal(card)
        }, 500)
      }
    },
    delete_card: function () {
      API.delete('credit_card/register/' + this.selected_card.id)
        .then(
          () => {
            location.reload()
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    open_default_card_modal: function (card) {
      try {
        fbq('track', 'InitiateCheckout')
        this.selected_card = card
        this.$refs.default_card_modal.show()
      } catch (error) {
        setTimeout(() => {
          this.open_default_card_modal(card)
        }, 500)
      }
    },
    default_card: function () {
      API.put('credit_card/set_default/' + this.selected_card.id)
        .then(
          () => {
            location.reload()
          },
          error => {
            this.handle_error(error)
          }
        )
    },
    open_tabela_de_precos_modal: function () {
      try {
        this.$refs.tabela_de_precos.show()
      } catch (error) {
        setTimeout(() => {
          this.open_tabela_de_precos_modal()
        }, 500)
      }
    },
    select_cable: function (type) {
      this.cable_type = type
      if (this.cableSelection === 0) {
        fbq('trackCustom', 'cableSelection')
        this.cableSelection++
      }
    },
    toogleCPF: function () {
      this.use_cpf = !this.use_cpf
    },
    open_new_tab: function (url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    sendPixel: function () {
      fbq('track', 'AddToCart')
    }
  }
}
