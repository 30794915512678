import Vue from 'vue'
import Router from 'vue-router'

import login from './components/login/index.vue'
import loginSocial from './components/login-social/index.vue'
import loginEmail from './components/login-email/index.vue'
import home from './components/home/index.vue'
import qr from './components/qr/index.vue'
import chargerQR from './components/charger_qr/index.vue'
import hotspot from './components/hotspot/index.vue'
import logout from './components/logout'
import singUp from './components/sing-up'
import forgotPassword from './components/forgot-password'
import maps from './components/maps/maps.vue'
import confirmAccount from './components/confirm_account'
import campaign from './components/campaign'
import history from './components/history'

import app from './App.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'app',
      component: app
    },
    {
      path: '/home',
      name: 'home',
      component: home
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/login-social',
      name: 'login_social',
      component: loginSocial
    },
    {
      path: '/login-email',
      name: 'login_email',
      component: loginEmail
    },
    {
      path: '/logout',
      name: 'logout',
      component: logout
    },
    {
      path: '/qr/:id',
      name: 'qr',
      component: qr
    },
    {
      path: '/charger_qr/:id',
      name: 'charger_qr',
      component: chargerQR
    },
    {
      path: '/hotspot',
      name: 'hotspot',
      component: hotspot
    },
    {
      path: '/sing-up',
      name: 'sing_up',
      component: singUp
    },
    {
      path: '/forgot-password',
      name: 'forgot_password',
      component: forgotPassword
    },
    {
      path: '/maps',
      name: 'maps',
      component: maps
    },
    {
      path: '/confirm-account/:code',
      name: 'confirm_account',
      component: confirmAccount
    },
    {
      path: '/vw/:token?',
      name: 'campaign',
      component: campaign
    },
    {
      path: '/history',
      name: 'history',
      component: history
    }
  ]
})
