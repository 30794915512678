
export default {
  name: 'login',
  components: {},
  props: [],
  created: function () {
    this.$router.push({
      name: 'login-social',
      params: {}
    })
  }
}
