export default {
  name: 'hotspot',
  components: {},
  props: [],
  data () {
    return {}
  },
  computed: {},
  mounted: function () {
    console.log('HYE')
  },
  methods: {}
}
