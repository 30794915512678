<template>
  <div id="app">
    <router-view />
    <div v-if="!external && language === 'en'">
      <img v-on:click="set_lang('pt-BR')" class="lang-img" src="./assets/images/brasil.jpg" alt />
    </div>
    <div v-if="!external && language === 'pt-BR'">
      <img v-on:click="set_lang('en')" class="lang-img" src="./assets/images/usa.jpg" alt />
    </div>
    <div>
      <a class="whatsapp-link" :href="$ml.get('login.whatsapp_msg')" target="_blank">
        <font-awesome-icon class="whatsapp-icon" :icon="{ prefix: 'fab', iconName: 'whatsapp' }" />
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'app',
  data: function () {
    return {
      language: 'pt-BR',
      external: false
    }
  },

  beforeCreate: function () {
    if (!this.$session.has('token')) {
      const publicPages = [
        'login_social',
        'login_email',
        'charges_qr',
        'sing_up',
        'forgot_password',
        'maps',
        'confirm_account',
        'campaign',
        'qr'
      ]
      if (!publicPages.includes(this.$route.name)) {
        this.$router.push({
          name: 'login_social',
          params: {}
        })
      }
    } else {
      axios.defaults.headers.common['Authorization'] = this.$session.get('token')
      if (this.$route.name === 'app') {
        this.$session.remove('campaign')
        this.$session.remove('campaignUrl')
        this.$router.push({
          name: 'home',
          params: {}
        })
      }
    }
  },
  mounted: function () {
    let lang = this.$session.get('lang')
    this.set_lang(lang)
  },
  methods: {
    set_lang: function (lang) {
      if (lang === undefined || lang === 'pt-BR') {
        this.$session.set('lang', 'pt-BR')
        this.$ml.change('portuguese')
        this.language = 'pt-BR'
        axios.defaults.headers.common['Accept-Language'] = 'pt-BR'
      } else if (lang === 'en') {
        this.$session.set('lang', 'en')
        this.$ml.change('english')
        this.language = 'en'
        axios.defaults.headers.common['Accept-Language'] = 'en'
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: main;
  src: url("./assets/fonts/main.otf");
}

html,
body {
  margin: 0 !important;
  font-family: main;
  letter-spacing: 1px;
}
</style>
