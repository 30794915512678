import axios from 'axios'
import mapStyles from '../../assets/styles/map_styles.json'
import iconNorm from '../../assets/images/icon_norm.png'
import iconShadow from '../../assets/images/icon_shadow.png'
import iconMidEn from '../../assets/images/icon_mid_en.png'
import iconMidPt from '../../assets/images/icon_mid_pt.png'
import iconMidEnShadow from '../../assets/images/icon_mid_en_shadow.png'
import iconMidPtShadow from '../../assets/images/icon_mid_pt_shadow.png'
import iconFullEn from '../../assets/images/icon_full_en.png'
import iconFullPt from '../../assets/images/icon_full_pt.png'
import iconFullEnShadow from '../../assets/images/icon_full_en_shadow.png'
import iconFullPtShadow from '../../assets/images/icon_full_pt_shadow.png'
import iconEmpty from '../../assets/images/icon_empty.png'
import iconMyLocation from '../../assets/images/my_location.png'

const mapOptions = {
  disableDefaultUI: true,
  autobindAllEvents: true,
  streetViewControl: false,
  clickableIcons: false,
  mapTypeControl: false,
  fullscreenControl: false,
  fullscreenControlOptions: {
    position: 5
  },
  styles: mapStyles,
  zoomControl: true,
  zoomControlOptions: {
    position: 8
  }
}

export default {
  name: 'maps',
  data: function () {
    return {
      center: {
        lat: -22.94846120444961,
        lng: -43.21081860241388
      },
      currentZoom: null,
      icon: {
        myLocation: iconMyLocation,
        empty: iconEmpty,
        norm: iconNorm,
        shadow: iconShadow,
        full: {
          en: {
            norm: iconFullEn,
            shadow: iconFullEnShadow
          },
          pt: {
            norm: iconFullPt,
            shadow: iconFullPtShadow
          }
        },
        mid: {
          en: {
            norm: iconMidEn,
            shadow: iconMidEnShadow
          },
          pt: {
            norm: iconMidPt,
            shadow: iconMidPtShadow
          }
        }
      },
      map: null,
      mapOptions: mapOptions,
      markerSelecterLatLng: null,
      myLocation: {
        lat: -22.94846120444961,
        lng: -43.21081860241388
      },
      open: true,
      selectedTerminal: null,
      showUnavailableTerminals: false,
      terminals: [],
      terminalType: 'SMARTPHONE/E-BIKE',
      urlGMaps: '',
      zoom: 15,
      terminalPhone: 1,
      terminalEbike: 2
    }
  },
  created: function () {
    this.getLocation()
    this.getTerminals()
    this.getCenter()
  },
  mounted: function () {
    // add map to data object
    this.$refs.map.$mapPromise.then((map) => {
      this.map = map
      if (this.myLocation.lat !== null && this.map !== null) {
        this.map.setCenter(this.myLocation)
      }
      this.$nextTick(() => {
        this.$refs.map.$gmapApiPromiseLazy().then(this.loadControls())
      })
    })
    this.filterNone()
    this.terminalType = 'SMARTPHONE/E-BIKE'
  },
  methods: {
    zoomChanged: function (zoom) {
      this.currentZoom = zoom
    },
    getCenter: function () {
      if (this.map !== null) {
        this.center = {
          lat: this.map.getCenter().lat(),
          lng: this.map.getCenter().lng()
        }
      }
    },
    dragEnd: function () {
      this.getCenter()
    },
    getLocation: function () {
      this.$getLocation({
        enableHighAccuracy: true
      }).then(
        coordinates => {
          this.myLocation = this.latlngGps(coordinates)
        }
      ).catch(
        error => {
          alert(error)
        }
      )
    },
    closeMarkerInfo: function () {
      this.selectedTerminal = null
    },
    onMarkerClick: function (terminal) {
      this.selectedTerminal = terminal
      this.map.panTo(this.latlng(this.selectedTerminal))
      var markerSelecterLatLng = this.latlng(terminal)
      this.urlGMaps =
        `http://maps.google.com/maps?q=loc:${markerSelecterLatLng.lat},${markerSelecterLatLng.lng}`
      let hourNow = new Date().getHours()
      if (terminal.close_hour > (terminal.open_hour)) {
        if (terminal.open_hour <= hourNow && hourNow < terminal.close_hour) {
          this.open = true
        } else {
          this.open = false
        }
      } else if (terminal.close_hour < terminal.open_hour) {
        if (terminal.close_hour < hourNow && hourNow <= terminal.open_hour) {
          this.open = false
        } else {
          this.open = true
        }
      } else {
        this.open = true
      }
    },
    latlng: function (elem) {
      return {
        lat: parseFloat(elem.geolocation.lat), lng: parseFloat(elem.geolocation.long)
      }
    },
    latlngGps: function (elem) {
      return {
        lat: parseFloat(elem.lat), lng: parseFloat(elem.lng)
      }
    },
    getTerminals: function () {
      axios.get(process.env.VUE_APP_MOBILE_API + 'points').then(
        response => {
          let terminals = response.data
          terminals = terminals.filter(value => {
            return value.is_avaible === true
          })
          terminals.forEach(terminal => {
            if (terminal.open_hour && terminal.close_hour) {
              terminal.open_hour_int = parseInt(`${terminal.open_hour}`)
              terminal.close_hour_int = parseInt(`${terminal.close_hour}`)
              terminal.open_hour_string = terminal.open_hour_int.toLocaleString('pt-BR', {
                minimumIntegerDigits: 2,
                useGrouping: false
              }) + 'h'
              terminal.close_hour_string = terminal.close_hour_int.toLocaleString('pt-BR', {
                minimumIntegerDigits: 2,
                useGrouping: false
              }) + 'h'
              let preOpen = (terminal.open_hour_int + 11) % 12 + 1
              let preClose = (terminal.close_hour_int + 11) % 12 + 1
              let sufOpen = terminal.open_hour_int >= 12 ? 'PM' : 'AM'
              let sufClose = terminal.close_hour_int >= 12 ? 'PM' : 'AM'
              terminal.open_hour_string_en = `${preOpen}${sufOpen}`
              terminal.close_hour_string_en = `${preClose}${sufClose}`
            }
            terminal.show = true
          })
          this.terminals = terminals
        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    },
    filterPhone: function () {
      this.terminals.forEach(terminal => {
        if (
          terminal.terminal_type === this.terminalPhone
        ) {
          terminal.show = true
        } else {
          terminal.show = false
        }
      })
      this.terminalType = 'SMARTPHONE'
    },
    filterEbike: function () {
      this.terminals.forEach(terminal => {
        if (
          terminal.terminal_type === this.terminalEbike
        ) {
          terminal.show = true
        } else {
          terminal.show = false
        }
      })
      this.terminalType = 'E-BIKE'
    },
    filterNone: function () {
      this.terminals.forEach(terminal => {
        terminal.show = true
      })
      if (this.$session.get('lang') === 'en') {
        this.terminalType = 'ALL'
      } else {
        this.terminalType = 'TODOS'
      }
    },
    // carregar o botão que move o mapa para o local do usuário
    loadControls: function () {
      var controlDiv = document.createElement('div')
      var firstChild = document.createElement('button')
      firstChild.style.backgroundColor = '#fff'
      firstChild.style.border = 'none'
      firstChild.style.outline = 'none'
      firstChild.style.width = '39px'
      firstChild.style.height = '39px'
      firstChild.style.borderRadius = '2px'
      firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)'
      firstChild.style.cursor = 'pointer'
      firstChild.style.marginRight = '11px'
      firstChild.style.padding = '0px'
      firstChild.title = 'Sua Localização'
      controlDiv.appendChild(firstChild)
      var secondChild = document.createElement('div')
      secondChild.style.margin = '10px'
      secondChild.style.width = '18px'
      secondChild.style.height = '18px'
      secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png)'
      secondChild.style.backgroundSize = '180px 18px'
      secondChild.style.backgroundPosition = '0px 0px'
      secondChild.style.backgroundRepeat = 'no-repeat'
      secondChild.id = 'you_location_img'
      firstChild.appendChild(secondChild)
      window.google.maps.event.addListener(this.$refs.map.$mapObject, 'center_changed', function () {
        secondChild.style['background-position'] = '0 0'
      })
      firstChild.addEventListener('click', function () {
        this.map.panTo(this.myLocation)
      }.bind(this))
      controlDiv.index = 1
      this.map.controls[8].push(controlDiv)
    },
    iconUrl: function (iconType) {
      var lang
      switch (this.$session.get('lang')) {
        case 'pt-BR':
          lang = 'p'
          break
        case 'en':
          lang = 'e'
          break
        default:
          lang = 'p'
          break
      }

      iconType = iconType + lang
      switch (iconType) {
        case 'ip':
        case 'ie':
          return this.icon.norm
        case 'isp':
        case 'ise':
          return this.icon.shadow
        case 'mp':
          return this.icon.mid.pt.norm
        case 'msp':
          return this.icon.mid.pt.shadow
        case 'fp':
          return this.icon.full.pt.norm
        case 'fsp':
          return this.icon.full.pt.shadow
        case 'me':
          return this.icon.mid.en.norm
        case 'mse':
          return this.icon.mid.en.shadow
        case 'fe':
          return this.icon.full.en.norm
        case 'fse':
          return this.icon.full.en.shadow
        default:
          return this.icon.norm
      }
    },
    scaledSize: function (iconType) {
      var scale = 6
      var size = { width: 132 / (scale - 3), height: 195 / (scale - 3) }
      var sizeMid = { width: 509 / (scale), height: 413 / (scale) }
      var sizeFull = { width: 509 / scale, height: 413 / scale }

      switch (iconType) {
        case 'i':
          return size
        case 'is':
          return size
        case 'm':
          return sizeMid
        case 'ms':
          return sizeMid
        case 'f':
          return sizeFull
        case 'fs':
          return sizeFull
      }
    },
    labelName: function (terminal) {
      let label = {
        text: '',
        fontSize: '0px'
      }
      let label1 = {
        text: terminal.name,
        color: '#000',
        fontSize: '11px',
        fontWeight: 'bold'
      }

      var iconType = this.iconType(terminal)
      switch (iconType) {
        case 'i':
          return label
        case 'is':
          return label
        case 'm':
          return label1
        case 'ms':
          return label1
        case 'f':
          return label1
        case 'fs':
          return label1
      }
    },
    labelOriginName: function (iconType) {
      var lableOrigin = {
        x: 0,
        y: 0
      }
      var lableOriginMid = {
        x: 20,
        y: 65
      }
      var lableOriginFull = {
        x: 45,
        y: 67
      }
      switch (iconType) {
        case 'i':
          return lableOrigin
        case 'is':
          return lableOrigin
        case 'm':
          return lableOriginMid
        case 'ms':
          return lableOriginMid
        case 'f':
          return lableOriginFull
        case 'fs':
          return lableOriginFull
      }
    },
    labelNum: function (terminal) {
      let label = {
        text: '',
        fontSize: '0px'
      }
      let label1 = {
        text: terminal.avaible_batteries,
        color: '#fff',
        fontSize: '27px'
      }
      let label2 = {
        text: terminal.avaible_batteries + ' ' + terminal.avaible_slots,
        color: '#fff',
        fontSize: '27px'
      }
      var iconType = this.iconType(terminal)
      switch (iconType) {
        case 'i':
          return label
        case 'is':
          return label
        case 'm':
          return label1
        case 'ms':
          return label1
        case 'f':
          return label2
        case 'fs':
          return label2
      }
    },
    labelOriginNum: function (iconType) {
      var lableOrigin = {
        x: 0,
        y: 0
      }
      var lableOriginMid = {
        x: 1,
        y: -30
      }
      var lableOriginFull = {
        x: 21,
        y: -30
      }
      switch (iconType) {
        case 'i':
          return lableOrigin
        case 'is':
          return lableOrigin
        case 'm':
          return lableOriginMid
        case 'ms':
          return lableOriginMid
        case 'f':
          return lableOriginFull
        case 'fs':
          return lableOriginFull
      }
    },
    anchorI: function (iconType) {
      var anchor1 = {
        x: 19,
        y: 50
      }
      var anchor2 = {
        x: 19,
        y: 60
      }
      switch (iconType) {
        case 'i':
          return anchor1
        case 'is':
          return anchor1
        case 'm':
          return anchor2
        case 'ms':
          return anchor2
        case 'f':
          return anchor2
        case 'fs':
          return anchor2
      }
    },
    iconType: function (terminal) {
      /* o tipo de icone utilizado depende da diferença de posição
      entre o centro do mapa e do terminal e do nível de zoom */

      // i = icone padrão | is = icone padrão com sombra
      // m = icone médio | ms = icone médio com sombra
      // f = icone full | fs = icone full com sombra
      let shadow = false
      if (this.selectedTerminal !== null) {
        if (terminal.id === this.selectedTerminal.id) {
          shadow = true
        }
      }
      let latlng = this.latlng(terminal)
      if (
        this.currentZoom === null) {
        return shadow ? 'is' : 'i'
      } else if (
        this.currentZoom < 14) {
        return shadow ? 'is' : 'i'
      } else if (
        this.currentZoom >= 14 &&
        this.currentZoom < 16 &&
        Math.abs(this.center.lat - latlng.lat) < 0.06 &&
        Math.abs(this.center.lng - latlng.lng) < 0.06) {
        return shadow ? 'ms' : 'm'
      } else if (
        this.currentZoom >= 16 &&
        Math.abs(this.center.lat - latlng.lat) < 0.015 &&
        Math.abs(this.center.lng - latlng.lng) < 0.015) {
        return shadow ? 'fs' : 'f'
      } else {
        return 'i'
      }
    },
    iconGen: function (terminal) {
      var iconType = this.iconType(terminal)
      return {
        url: this.iconUrl(iconType),
        labelOrigin: this.labelOriginName(iconType),
        scaledSize: this.scaledSize(iconType),
        anchor: this.anchorI(iconType)
      }
    },
    iconGenSecondary: function (terminal) {
      var iconType = this.iconType(terminal)
      return {
        url: this.icon.empty,
        labelOrigin: this.labelOriginNum(iconType),
        scaledSize: { width: 1, height: 1 }
      }
    }
  }
}
