
const LogoutComponent = {
  name: 'logout',
  components: {},
  mounted: function () {
    this.logout()
  },
  methods: {
    logout: function () {
      this.$session.remove('token')
      this.$session.remove('campaign')
      this.$session.remove('campaignUrl')
      this.$router.push({
        name: 'login_social',
        params: {}
      })
    }
  }
}

export default LogoutComponent
