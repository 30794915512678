import { isIOS } from 'mobile-device-detect'

export default {
  name: 'charger_qr',
  components: {},
  props: [],
  data () {
    return {}
  },
  computed: {},
  mounted: function () {
    if (isIOS) {
      location.replace('https://itunes.apple.com/us/app/energy2go/id1299206215?l=pt&ls=1&mt=8')
    } else {
      location.replace(
        'https://play.google.com/store/apps/details?id=com.ionicframework.project522895'
      )
    }
  },
  methods: {}
}
