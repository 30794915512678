import API from '../../services/api'

export default {
  name: 'sing_up',
  components: {},
  props: [],
  data: function () {
    return {
      style: {
        bg: '#1f214c',
        secondary_bg: '#ffffff'
      },
      errors: [],
      message: null,
      input: {
        email: '',
        password: '',
        passwordConfirmation: '',
        userTerms: false
      }
    }
  },
  computed: {},
  methods: {
    singUp: function (e) {
      const validateForm = (input) => {
        this.errors = []
        this.message = null
        const { email: mail, password: pwd, passwordConfirmation, userTerms } = input

        const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (mailformat.test(mail) === false) {
          this.errors.push({ email: ['sing_up.invalid_email'] })
        }
        if (!mail) {
          this.errors.push({ email: ['sing_up.required_email'] })
        }
        if (!userTerms) {
          this.errors.push({ userTerms: ['sing_up.mandatory_user_terms'] })
        }
        if (pwd !== passwordConfirmation) {
          this.errors.push({ password: ['sing_up.password_doesnt_match'] })
        }
        return this.errors.length === 0
      }

      if (!validateForm(this.input)) {
        e.preventDefault()
        return
      }
      const { email, password } = this.input
      API.post('customer/register', { email, password }).then(res => {
        if (res.data.success === true) {
          this.$router.push({
            name: 'login_email',
            params: {}
          })
        } else {
          this.message = res.data.message
          this.errors.push(res.data.errors)
        }
      }).catch(error => {
        console.log(error)
      })

      e.preventDefault()
    }
  }
}
