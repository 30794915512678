import { render, staticRenderFns } from "./login-social.html?vue&type=template&id=735b2c29&scoped=true&"
import script from "./login-social.js?vue&type=script&lang=js&"
export * from "./login-social.js?vue&type=script&lang=js&"
import style0 from "./login-social.scss?vue&type=style&index=0&id=735b2c29&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "735b2c29",
  null
  
)

export default component.exports