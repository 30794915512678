/* global fbq */
import axios from 'axios'
import API from '../../services/api'

export default {
  name: 'login-email',
  props: [],
  data: function () {
    return {
      style: {
        bg: '#1f214c',
        secondary_bg: '#5258c2'
      },
      errors: [],
      input: {
        email: '',
        password: ''
      }
    }
  },
  computed: {},
  mounted: function () { },
  methods: {
    login: function (e) {
      try {
        this.errors = []
        const { email, password } = this.input
        if (!email) {
          this.errors.push('mandatory_email')
        }
        if (!password) {
          this.errors.push('mandatory_password')
        }
        API.post('customer/login', { email, password }).then(res => {
          if (res.data.success) {
            fbq('track', 'CompleteRegistration', { content_name: 'email' })
            console.log('Login realizado com sucesso!')
            this.$session.set('token', res.data.token)
            axios.defaults.headers.common['Authorization'] = res.data.token
            this.$router.push({
              name: 'home',
              params: {}
            })
          } else {
            this.errors.push(res.data.errors)
          }
        })
      } catch (error) {
        console.log(error)
      }
      e.preventDefault()
    }
  }
}
