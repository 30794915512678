export default {
  name: 'qr',
  components: {},
  props: [],
  data () {
    return {}
  },
  computed: {},
  mounted: function () {
    let type = this.$route.path.split('/')[1]
    if (type === 'qr') {
      let token = this.$route.path.split('/')[2]
      this.$session.set('terminal_token', token)
      this.$router.push({
        name: 'app',
        params: {}
      })
    }
  },
  methods: {}
}
