
import API from '../../services/api'

export default {
  name: 'confirm_account',
  components: {},
  props: [],
  data () {
    return {
      style: {
        bg: '#016ab3',
        secondary_bg: '#ffffff'
      },
      message: 'confirm_account.waiting',
      error: null
    }
  },
  created: function () {
    this.confirmAccount()
  },
  methods: {
    confirmAccount () {
      const self = this
      self.error = null
      const code = this.$route.params.code
      API.get('customer/confirm_account/' + code).then(function (resp) {
        const confirmed = resp.data.success === true
        self.message = confirmed ? 'confirm_account.confirmation_successful' : 'confirm_account.confirmation_failed'
        if (confirmed) {
          setTimeout(() => {
            this.$router.push({
              name: 'login',
              query: {
                reference: 'confirm-account'
              }
            })
          }, 5000)
        }
      }).catch(function (e) {
        const { response: resp } = e
        const unsuccessfull = resp.data.success === false
        if (unsuccessfull) {
          self.error = resp.data.error
          self.message = 'confirm_account.confirmation_failed'
        }
      })
    }
  }
}
